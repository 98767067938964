<template>
        <div class="container">
			<div class="row">
				<div class="col-12">
					<div class="sign__content">
						<!-- authorization form -->
                        <form v-on:submit.prevent="AddUsers()" class="sign__form">
                            <a href="index.php" class="sign__logo">
                                <img src="assets/img/logoSvg.svg" alt="">
							</a>
							<div class="sign__group">
                                <span class="sign__delimiter">رقم الهاتف </span>
                                <input type="phone" v-model="phone" class="sign__input" pattern="^\+?(?:[0-9]??).{9,13}[0-9]$" placeholder="09XXXXXXXX" >
							</div>
                            <div class="sign__group">
                            <button class="sign__btn" type="submit">اشتراك</button>
                            <span class="sign__delimiter"><h6 style="text-align: center;">سوف تصلك رسالة نصية تحتوي علي كود التفعيل</h6></span>
							</div>
						</form>
						<!-- end authorization form -->
					</div>
				</div>
			</div>
	    </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
    name: 'Signups',
    data() {
        return {
          phone: "",
        };
    },
  methods: {
    AddUsers() {
        const toast = useToast();
        axios.post(
            "DSPSubscribtionRequest.php?msisdn="+this.phone+"&product_code="+this.$cookie.getCookie('product_code')+""
          ).then((response) => {
              if (response.data.responseCode == 0 && response.data.error == false) {
                  this.$cookie.setCookie('requestId', response.data.requestId, {
                     expire: 60 * 5,
                  })
                  this.$cookie.setCookie('mdn', this.phone, {
                     expire: 60 * 5,
                  })
                   toast.success("رقم الهاتف صحيح ادخل رمز التاكيد", {
                      timeout: 4000
                   });
                   setTimeout( () => 
                   this.$router.push({ name: "Virefy"
                  }),
                4000
              );
              } else if ( response.data.error == true && response.data.responseCode == 113) {
                  // user active
                  this.$cookie.removeCookie("product_code");
                  this.$cookie.removeCookie("SUBID");
                  toast.info("انت مشترك مسبقا الرجاء تسجيل الدخول", {
                      timeout: 4000
                   });
                   setTimeout(
                   () =>
                     this.$router.push({
                        name: "Login",
                     }),
                  4000
                 );
              } else if (response.data.error == true && response.data.responseCode == 118) {
                // user not active
                this.$cookie.removeCookie("product_code");
                this.$cookie.removeCookie("SUBID");
                toast.info("لم يتم تجديد اشتراكك بعد", {
                      timeout: 4000
                   });
                 setTimeout(
                   () =>
                     this.$router.push({
                        name: "/",
                     }),
                  4000
                 );
              }
              else if (response.data.error == true && response.data.responseCode == "" ) {
                // user not active
                this.$cookie.removeCookie("product_code");
                this.$cookie.removeCookie("SUBID");
                toast.error("انت مشترك مسبقا الرجاء تسجيل الدخول", {
                      timeout: 4000
                   });
                 setTimeout(
                   () =>
                     this.$router.push({
                        name: "Login",
                     }),
                  4000
                 );
              } else {
                  toast.error("حدثت مشكلة الرجاء المحاولة مرة اخري", {
                      timeout: 4000
                   });
            }
          })
    }
  }
}
</script>

<style scoped>

</style>